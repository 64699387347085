import React, { useEffect, useState } from 'react';

import {
  Questions,
  Payment,
  Details,
  Schedule,
  Teachers,
  Structure,
  EmploymentsShortDescription,
  ProjectsAndReviews,
  ProjectsContent,
  GraduatesContent,
  EntranceInterview,
  HowDoesBootcampWork,
  EducationOnlineFormat,
  NarrowGreetings,
} from '../widgets';

import { parseDate } from '../helpers/lib';
import { getCourse, getNearestGroups, getProgramGroups } from '../api/strapi';
import { sendYandexMetrikaEvent } from '../helpers/yandexMetrika';
import Layout from '../shared/Layout/Layout';
import { useGreetingsQuery } from '../app/queries/greetings.query';
import { useProjectsQuery } from '../app/queries/projects.query';
import { useScheduleImgQuery } from '../app/queries/scheduleImg.query';
import { useProgramQuery } from '../app/queries/program.query';
import { usePaymentQuery } from '../app/queries/payment.query';
import { useEcbWorksQuery } from '../app/queries/howecbworks.query';
import { usePnRQuery } from '../app/queries/projectsAndReviews.query';
import { useStudentsQuery } from '../app/queries/students.query';
import { useStudentsWorkQuery } from '../app/queries/studentsWork.query';
import { useDevPageQuery } from '../app/queries/webdevpage.query';

import ExamPreparation from '../shared/ExamPreparation/ExamPreparation';

import HeadMaker from '../HeadMaker';
import data from '../pages-fsd/OnlineJsPage/model/online.data';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

const mainData = {
  leadPage: 'JS Online_Основная программа',
  program: 'JS Online',
  tags: ['онлайн'],
};

const leadData = {
  question: {
    ...mainData,
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
  },
  greeting: {
    ...mainData,
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Топ',
  },
  request: {
    ...mainData,
    eventName: 'Full Stack (Javascript)',
    leadAction: 'Заявка на программу',
    leadBlock: 'Топ',
  },
  onlineFormats: {
    ...mainData,
    eventName: 'Full Stack (Javascript)',
    leadAction: 'Заявка на программу',
    leadBlock: 'Форматы обучения и стоимость',
  },
  loan: {
    ...mainData,
    eventName: 'Full Stack (Javascript)',
    leadAction: 'Заявка на рассрочку',
    leadBlock: 'Стоимость',
  },
  discount: {
    ...mainData,
    eventName: 'Full Stack (Javascript)',
    leadAction: 'Оплатить',
    leadBlock: 'Стоимость',
  },
  groups: {
    ...mainData,
    eventName: 'Full Stack (Javascript)',
    leadAction: 'Заявка на программу',
    leadBlock: 'Потоки',
  },
  structure: {
    ...mainData,
    eventName: 'Получить подробную программу',
    leadBlock: 'Структура программы',
    leadAction: 'Получить подробную программу',
  },
};

const PROGRAM = 'JS';
const LOCATION = 'ONLINE';

function OnlineJsPage() {
  const [group, setGroup] = useState({});
  const [isGroupLoading, setIsGroupLoading] = useState(true);
  const [teachers, setTeachers] = useState([]);
  const [prepGroup, setPrepGroup] = useState(null);
  const { greetingsImgs } = useGreetingsQuery();
  const { projectsData } = useProjectsQuery();
  const { scheduleImg } = useScheduleImgQuery();
  const { programImgs } = useProgramQuery();
  const { ecbWorksImgs } = useEcbWorksQuery();
  const { paymentImgs } = usePaymentQuery();
  const { reviewsImgs } = usePnRQuery();
  const { studentsData } = useStudentsQuery();
  const { studentsWorkData } = useStudentsWorkQuery();
  const { devPageImgs } = useDevPageQuery();

  useEffect(() => {
    const ac = new AbortController();

    sendYandexMetrikaEvent('mainProgramView');

    getProgramGroups(ac, 'PREPARE_JS').then(
      (groups) => groups && setPrepGroup(groups[0]),
    );
    getNearestGroups(ac, PROGRAM, LOCATION)
      .then((data) => {
        setGroup(data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsGroupLoading(false);
      });
    getNearestGroups(ac, PROGRAM, LOCATION).then((data) => {
      setGroup(data);
    });

    getCourse(ac, 'online_js')
      .then((data) => setTeachers(data[0].teacher))
      .catch((e) => console.error(e));

    return () => {
      ac.abort();
    };
  }, []);
  return (
    <Layout leadPage="JS Online_Основная программа">
      <NarrowGreetings
        {...data.greetings}
        bg="greeting-js"
        labelTheme="paleBlue"
        image={greetingsImgs['greetings/online-js']}
        extraLeadData={leadData.greeting}
        requestLeadData={leadData.request}
        group={group}
        isLoading={isGroupLoading}
      />
      <Details {...data.details} />
      <Teachers {...data.teachers} teachers={teachers} />
      <Schedule {...data.schedule} images={scheduleImg} />
      <Structure
        {...data.structure}
        bg={'common-programs'}
        leadData={leadData.structure}
        programImgs={programImgs}
        program="js"
      />
      <EmploymentsShortDescription data={data.employmentDescription} />
      <ProjectsAndReviews {...data.projectsAndReviews} images={reviewsImgs}>
        <ProjectsContent {...data.projects} projectsData={projectsData} />
        <GraduatesContent
          {...data.graduates}
          data={{
            people: data.graduates.data.people,
          }}
          studentsData={studentsData}
          studentsWorkData={studentsWorkData}
          noBg
        />
      </ProjectsAndReviews>
      <EducationOnlineFormat
        {...data.educationFormat}
        images={devPageImgs}
        requestLeadData={leadData.onlineFormats}
      />
      <Payment
        {...data.payment}
        imgs={paymentImgs}
        group={group}
        loanLeadData={leadData.loan}
        discountLeadData={leadData.discount}
        noPrice
      />
      <EntranceInterview {...data.entranceInterview} />
      <ExamPreparation
        {...data.examPrepare}
        startDate={parseDate(prepGroup?.startDate)?.slice(1).join(' ')}
        priceData={prepGroup?.price}
        img={programImgs['program/prepareTitle']}
      />
      <HowDoesBootcampWork
        images={ecbWorksImgs}
        {...data.howDoesBootcampWork}
      />
      <Questions {...data.questions} extraLeadData={leadData.question} />
    </Layout>
  );
}

export default OnlineJsPage;
